import React from "react";
import Hero from "../components/home/hero";
import Products from "../components/home/products";
import Partners from "../components/home/partner";
import Onboarding from "../components/home/onboarding";
import Statistics from "../components/home/statistics";
import Proposal from "../components/general/proposal";
import News from "../components/home/news";

const Home = () => {
  return (
    <div className="home_container">
      <Hero></Hero>
      <section id="products">
        <Products></Products>
      </section>
      <section id="partner">
        <Partners></Partners>
      </section>
      <section id="onboarding">
        <Onboarding></Onboarding>
      </section>
      <section id="feedback">
        <Statistics></Statistics>
      </section>
      <section id="proposal">
        <Proposal></Proposal>
      </section>
      <section id="news">
        <News></News>
      </section>
    </div>
  );
};

export default Home;
