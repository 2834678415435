import React, { useEffect } from 'react';

const Demo = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', margin:'10px'}}>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/TAJkOsUQV2k?si=A4IV0Gq1VUAr-JEF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    );
}

export default Demo;
