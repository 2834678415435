import React from "react";
import "../../styles/home/products.css";
import { logoCareBridge, logoGreen, logoLabSync } from "../../utils/logo";
import { BsFileMedical, BsHospitalFill, BsPlusCircle } from "react-icons/bs";
import { MdLogin } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const navigate = useNavigate();
  return (
    <div className="product_container">
      <div className="product_content product_content_left">
        <img
          src={logoLabSync}
          style={{ height: "50px" }}
          alt="MyHospitul Logo"
        />
        <h3 className="fg-">MyHospitul LabSync</h3>
        <p>
          LabSync simplifies the process of sending lab reports, tracking
          values, and managing laboratory results. It also enables patients to
          conveniently book lab procedures and handle payments with ease.
        </p>
        <div className="flex-center">
          <div className="button-primary" onClick={() => alert("Coming soon")}>
            <BsPlusCircle></BsPlusCircle>
            <span>Sign up</span>
          </div>
          <div
            className="button-secondary"
            onClick={() => alert("Coming soon")}
          >
            <span>Login</span>
            <MdLogin></MdLogin>
          </div>
        </div>
      </div>
      <div className="product_content product_content_enlarged">
        <img src={logoGreen} alt="MyHospitul Logo" />
        <h3 className="fg-primary">MyHospitul Enterprise</h3>
        <p>
          Effortlessly manage and share medical records, laboratory reports,
          drug records, along with patients' bio data. While enjoying fast and
          automated appointment billing, debt tracking, and easy scheduling for
          both hospitals and patients.
        </p>
        <div className="button-primary" onClick={()=>navigate("/demo")}>View Demo</div>
      </div>
      <div className="product_content product_content_right">
        <img
          src={logoCareBridge}
          alt="MyHospitul Logo"
          style={{ height: "50px" }}
        />
        <h3 className="fg-primary">MyHospitul CareBridge</h3>
        <p>
          CareBridge is a platform where hospitals, laboratories, pharmacies,
          and medical insurance companies can connect with certified healthcare
          professionals, such as lab scientists, radiologists, pharmacists,
          nurses, and medical doctors. These professionals have also completed
          training courses on using EMRs and adhering to best medical practices.
        </p>
        <div className="flex-center">
          <div className="button-primary" onClick={() => alert("Coming soon")}>
            <BsHospitalFill></BsHospitalFill>
            <span>Provider</span>
          </div>
          <div className="button-red" onClick={() => alert("Coming soon")}>
            <span>Professional</span>
            <BsFileMedical></BsFileMedical>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
