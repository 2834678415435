import axios from "axios";
export const SendProposal = async (body) => {
  try {
    const response = await axios.post(
      "https://app-bfj66olweq-uc.a.run.app/util/emr-request",
      body
    );
    alert(response.data);
  } catch {
    alert("Error Occurred");
  }
};
