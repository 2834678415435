import React from 'react';
import "../../styles/home/news.css"

const News = () => {
    return (
        <div className='flex-center backdrop'>
            <div className='dark_backdrop'></div>
            <img src={require("../../assets/images/promo.jpg")} alt="MyHospitul Promo" className='promo'/>
        </div>
    );
}

export default News;
