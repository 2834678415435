import React from "react";
import "../../styles/home/statistics.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsPersonCircle } from "react-icons/bs";

const feedbacks = [
  {
    name: "Frontdesk, GoodHeart Hospital",
    tours:
      "This is the best thing that happened to Goodheart. Easy to learn and it makes work faster. Kudos",
  },
  {
    name: "CMO, GoodHeart",
    tours:"A good medical record application.... Fast Customer Service response",
  },
  {
    name: "CMD, Clared",
    tours:"The Application is Satisfactory and meets all our needs",
  },
  {
    name: "CMD, Rehoboth",
    tours:"We have been using MyHospitul for 2 years now and their customer service response is top-notch",
  },
];

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
};
const settings_mobile = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
};

const Statistics = () => {
  return (
    <div>
      <div className="flex-center section_title">
        <div>
          <svg width="200" height="20" xmlns="http://www.w3.org/2000/svg">
            <polygon
              points="50,10 150,10 180,140 20,140"
              fill="#016400"
              stroke="#016400"
              stroke-width="2"
            />
          </svg>
        </div>
        <h3 className="fg-shade-one">Our Success stories</h3>
        <div>
          <svg width="200" height="20" xmlns="http://www.w3.org/2000/svg">
            <polygon
              points="50,10 150,10 180,140 20,140"
              fill="#016400"
              stroke="#016400"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
      <div className="stat_container">
        <div className="stat_content">
          <div className="stat_circle_holder">
            <div className="stat_circle">30+</div>
          </div>
          <p>
            Hospitals <br></br>Laboratories
          </p>
        </div>
        <div className="stat_content">
          <div className="stat_circle_holder">
            <div className="stat_circle">4</div>
          </div>
          <p>
            States <br></br>Across Nigeria
          </p>
        </div>
        <div className="stat_content">
          <div className="stat_circle_holder">
            <div className="stat_circle">20M+</div>
          </div>
          <p>
            Debt <br></br>Recovery
          </p>
        </div>
        <div className="stat_content">
          <div className="stat_circle_holder">
            <div className="stat_circle">100k</div>
          </div>
          <p>
            Cost Savings <br></br>per month
          </p>
        </div>
      </div>
      <br></br>
      <div className="stat_dashboard_image">
        <img
          src={require("../../assets/images/general/dashboard.png")}
          alt="MyHospitul Dashboard"
        />
      </div>
      <div className="review_container">
        <div className="review_container_web hide-mobile">
          <Slider {...settings}>
            {feedbacks.map((feedbacks, index) => (
              <div key={index} className="review_content">
                  <BsPersonCircle
                    style={{ fontSize: "60px"}}
                  ></BsPersonCircle>
                  <h5>{feedbacks.name}</h5>
                  <p>{feedbacks.tours}</p>
              </div>
            ))}
          </Slider>
        </div>
        <div className="review_container_web hide-desktop">
          <Slider {...settings_mobile}>
            {feedbacks.map((feedbacks, index) => (
              <div key={index} className="review_content">
                  <BsPersonCircle
                    style={{ fontSize: "60px"}}
                  ></BsPersonCircle>
                  <h5>{feedbacks.name}</h5>
                  <p>{feedbacks.tours}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
