import React from "react";
import { FaSuitcaseMedical } from "react-icons/fa6";
import "../../styles/home/hero.css";
import { CgShapeZigzag } from "react-icons/cg";

const Hero = () => {
  return (
    <div className="hero_container">
      <div className="hero_container_one">
        <p style={{ fontSize: "11px", color: "whitesmoke", fontWeight:'bold' }}>
          <FaSuitcaseMedical style={{ marginRight: "5px",color:'whitesmoke' }} /> Hospital |
          Laboratory | Pharmacy
        </p>
        <h1 className="fg-white go_paperless">
          Go Paperless <br></br>
          in Just 15 Weeks with Our <br></br>
          Digital Healthcare Solution!
        </h1>
        <p className="go_paperless_note">
          Designed to put your patients first, delivering top-tier performance
          through innovation, and with the highest standard of excellence and
          data security — our medical record solution is built to support your
          success!
        </p>
        <div className="flex-left go_paperless_design animated-div">
          <CgShapeZigzag
            style={{ color: "#32cd32", fontSize: "70px", minWidth: "70px" }}
          />
          <CgShapeZigzag
            style={{ color: "#32cd32", fontSize: "70px", minWidth: "70px" }}
          />
          <CgShapeZigzag
            style={{ color: "#32cd32", fontSize: "70px", minWidth: "70px" }}
          />
          <CgShapeZigzag
            style={{ color: "#32cd32", fontSize: "70px", minWidth: "70px" }}
          />
        </div>
      </div>
      <div className="hero_container_two">
        <div className="rotating-circle">
          <div className="rotating-circle-internal"></div>
        </div>
        <img
          alt="myhospitul technology frames"
          src={require("../../assets/images/mhcframes/desktop.png")}
        />
      </div>
    </div>
  );
};

export default Hero;
