// src/components/Partners.js
import React, { useEffect, useRef } from "react";
import "../../styles/home/partners.css";
const logos = [
  {
    logo: null,
    title: "Port Harcourt",
  },
  {
    logo: require("../../assets/images/partners/goodheart.jpeg"),
    name: "",
  },
  {
    logo: require("../../assets/images/partners/rehoboth.png"),
    name: "Rehoboth",
  },
  {
    logo: require("../../assets/images/partners/kariden.png"),
    name: "Kariden Diabetes & Endocrine Center",
  },
  {
    logo: require("../../assets/images/partners/clared.jpeg"),
    name: "Clared Hospital",
  },
  {
    logo: require("../../assets/images/partners/capstone.jpeg"),
    name: "",
  },
  {
    logo: null,
    name: "Onne Medicals Center",
  },
  {
    logo: require("../../assets/images/partners/sonocare.png"),
    name: "",
  },
  {
    logo: null,
    name: "Wills Medical Center",
  },
  {
    logo: require("../../assets/images/partners/mast.jpeg"),
    name: "Mast Medicals",
  },
  {
    logo: null,
    name: "Bareke Memorial Hospital",
  },
  {
    logo: null,
    name: "Crystal Diagnostics Center",
  },
  {
    logo: null,
    title: "Uyo",
  },
  {
    logo: require("../../assets/images/partners/romivic.png"),
    name: "Romivic",
  },
  {
    logo: require("../../assets/images/partners/milestone.png"),
    name: "Milestone",
  },
  {
    logo: require("../../assets/images/partners/shelterd.jpeg"),
    name: "",
  },
  {
    logo: null,
    title: "Asaba",
  },
  {
    logo: require("../../assets/images/partners/kencrest.png"),
    name: "",
  },
  {
    logo: null,
    name: "House of Hope Clinic",
  },
  {
    logo: null,
    title: "Aba",
  },
  {
    logo: null,
    name: "Mother Mercy Medical Center",
  },
  {
    logo: null,
    name: "Grace Touch Hospital",
  },
];

const Partners = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const totalWidth = containerRef.current.scrollWidth;
      const viewportWidth = containerRef.current.clientWidth;
      containerRef.current.style.setProperty(
        "--total-width",
        `${totalWidth}px`
      );
      containerRef.current.style.setProperty(
        "--viewport-width",
        `${viewportWidth}px`
      );
    }
  }, []);

  return (
    <div>
      <div className="flex-center section_title">
        <div>
          <svg width="200" height="20" xmlns="http://www.w3.org/2000/svg">
            <polygon
              points="50,10 150,10 180,140 20,140"
              fill="#016400"
              stroke="#016400"
              stroke-width="2"
            />
          </svg>
        </div>
        <h3 className="fg-shade-one">
          Join Over 30 Healthcare Providers using our solution
        </h3>
        <div>
          <svg width="200" height="20" xmlns="http://www.w3.org/2000/svg">
            <polygon
              points="50,10 150,10 180,140 20,140"
              fill="#016400"
              stroke="#016400"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
      <div className="logo-slider">
        <div className="logo-container" ref={containerRef}>
          {logos.map((logo, index) => (
            <div className="flex-center">
              {logo.title ? <p className="title_name">{logo.title} </p> : <></>}
              {logo.logo ? (
                <img
                  key={index}
                  src={logo.logo}
                  alt={`Logo ${index + 1}`}
                  className="logo"
                />
              ) : (
                <div></div>
              )}
              {logo.name ? <p className="logo_name">{logo.name}</p> : <></>}
            </div>
          ))}
          {logos.map((logo, index) => (
            <div className="flex-center">
              {logo.title ? <p className="title_name">{logo.title} </p> : <></>}
              {logo.logo ? (
                <img
                  key={index}
                  src={logo.logo}
                  alt={`Logo ${index + 1}`}
                  className="logo"
                />
              ) : (
                <div></div>
              )}
              {logo.name ? <p className="logo_name">{logo.name}</p> : <></>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
