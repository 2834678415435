import React, { useState } from "react";
import "../../styles/proposal.css";
import { SendProposal } from "../../services/general";
// import { GetQuote } from "../server/server";

const Proposal = () => {
  const [fullname, fullnameChanged] = useState("");
  const [role, roleChanged] = useState("");
  const [hospitalName, hospitalNameChanged] = useState("");
  const [hospitalAddress, hospitalAddressChanged] = useState("");
  const [computers, computersChanged] = useState("");
  const [email, emailChanged] = useState("");
  const [phone, phoneChanged] = useState("");
  const [provider, providerChanged] = useState("");
  const [description, descriptionChanged] = useState("");
  const sendQuote = async () => {
    await SendProposal({
      name: fullname,
      email: email,
      phone: phone,
      hospitalName: hospitalName,
      hospitalAddress: hospitalAddress,
      role: role,
      organisationType: provider,
      computers: computers,
      description: description,
    });
    fullnameChanged("");
    emailChanged("");
    phoneChanged("");
    hospitalNameChanged("");
    hospitalAddressChanged("");
    roleChanged("--Select Current Role--")
    providerChanged("--Select Healthcare Provider--")
    computersChanged("")
    descriptionChanged("")
  };
  return (
    <div className="contact" id="contact">
      <div className="contact-section">
        <div className="get-in-touch">
          <h2>EMR Request Form</h2>
          <p>We typically reply within 10minutes!</p>
          <div className="contact-grid">
            <div>
              <input
                type="text"
                name="name"
                placeholder="Fullname (required)"
                value={fullname}
                onChange={(e) => fullnameChanged(e.target.value)}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email (required)"
                value={email}
                onChange={(e) => emailChanged(e.target.value)}
                required
              />
              <input
                type="text"
                name="hospital"
                placeholder="Hospital Fullname (required)"
                value={hospitalName}
                onChange={(e) => hospitalNameChanged(e.target.value)}
                required
              />

              <input
                type="text"
                className="contact-budget"
                name="subject"
                placeholder="Estimated Number of Computers"
                value={computers}
                onChange={(e) => computersChanged(e.target.value)}
              />
              <select
                name="status"
                required
                value={provider}
                onChange={(e) => providerChanged(e.target.value)}
              >
                <option value="" id="service" disabled selected>
                  --Select Healthcare Provider--
                </option>
                <option value="flight">Hospital</option>
                <option value="visa">Diagnostic Center</option>
                <option value="tour">Laboratory</option>
                <option value="flight">Pharmacy</option>
                <option value="flight">Insurance/HMO</option>
              </select>
            </div>
            <div>
              <select
                name="status"
                required
                value={role}
                onChange={(e) => roleChanged(e.target.value)}
              >
                <option value="" id="service" disabled selected>
                  --Select Current Role--
                </option>
                <option value="flight">Medical Director</option>
                <option value="flight">
                  Doctor/Nurses/Pharmacist/Scientist
                </option>
                <option value="tour">Administrator</option>
                <option value="visa">ICT</option>
              </select>
              <input
                type="tel"
                name="subject"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => phoneChanged(e.target.value)}
              />
              <input
                type="text"
                name="subject"
                placeholder="Hospital Address with state of residence"
                value={hospitalAddress}
                onChange={(e) => hospitalAddressChanged(e.target.value)}
              />
              <textarea
                style={{ width: "100%" }}
                name="message"
                placeholder="Tell us about your medical center"
                value={description}
                onChange={(e) => descriptionChanged(e.target.value)}
              />
            </div>
          </div>
          <br></br>
          <button onClick={() => sendQuote()}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Proposal;
