import React, { useState } from "react";
import "../../styles/header.css";
import { FaQuestionCircle } from "react-icons/fa";
import { BsCircleFill } from "react-icons/bs";
import Hamburger from "hamburger-react";
import { useNavigate } from "react-router-dom";
import { logoGreen, logoWhite } from "../../utils/logo";

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const routeTo = (page) => {
    const id = page.replace("#", "");
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
    console.log(element);
  };
  return (
    <div className="hd-parent bg-shade-one-dark">
      <div className="hd-space-between bg-shade-one-dark flex-center">
        <div className="hide-mobile">
          <LogoFunction />
        </div>
        <div className="flex-left flex-gap-mini">
          {/* Menu Layer - Webpage*/}
          <div className="hide-mobile">
            <div className="hd-menu-bar">
              <div className="hd-menu-main bg-shade-one-dark">
                <div className="hd-menu">
                  <div className="hd-menu-con" onClick={() => navigate("/")}>
                    <p>Home</p>
                    <div className="hd-menu-line"></div>
                  </div>
                  <div
                    className="hd-menu-con"
                    onClick={() => routeTo("#feedback")}
                  >
                    <p>Testimonials</p>
                    <div className="hd-menu-line"></div>
                  </div>
                  <div
                    className="hd-menu-con"
                    onClick={() => routeTo("#products")}
                  >
                    <p>Products</p>
                    <div className="hd-menu-line"></div>
                  </div>
                  <div
                    className="hd-menu-con"
                    onClick={() => routeTo("#onboarding")}
                  >
                    <p>How it works</p>
                    <div className="hd-menu-line"></div>
                  </div>
                </div>
                <div className="header-button header-button-web">
                  <a href="#proposal">Make Enquiry</a>
                  <FaQuestionCircle />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p onClick={()=>routeTo("#news")} className="moving-text">Promo Alert!!! - Get a Free Laptop and MyHospitul installation and licensing for <b>350k</b> only <span style={{color:'#016400',backgroundColor:'white',padding:'3px 10px',borderRadius:'5px'}}>Read more</span></p>
      {/* Menu Layer - Mobilepage*/}
      <div className="hide-desktop">
        <br></br>
        <div className="hd-menu-bar-mobile">
          <div className="hd-menu-main-mobile bg-shade-one-dark">
            <LogoFunction />
            <div className="hamburger">
              <Hamburger
                toggled={isOpen}
                size={25}
                toggle={setOpen}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="hide-desktop">
        <div
          className={
            isOpen ? "hd-menu-mobile" : "hd-menu-mobile hd-menu-mobile-hide"
          }
        >
          <div
            className={isOpen ? "hd-menu-con" : "hide"}
            onClick={() => routeTo("/")}
          >
            <p>Home</p>
            <div className="hd-menu-line"></div>
          </div>
          <p className={isOpen ? "" : "hide"}>
            <BsCircleFill style={{ fontSize: "3px" }}></BsCircleFill>
          </p>
          <div
            className={isOpen ? "hd-menu-con" : "hide"}
            onClick={() => routeTo("#feedback")}
          >
            <p>Testimonials</p>
            <div className="hd-menu-line"></div>
          </div>
          <p className={isOpen ? "" : "hide"}>
            <BsCircleFill style={{ fontSize: "3px" }}></BsCircleFill>
          </p>
          <div
            className={isOpen ? "hd-menu-con" : "hide"}
            onClick={() => routeTo("#products")}
          >
            <p>Products</p>
            <div className="hd-menu-line"></div>
          </div>
          <p className={isOpen ? "" : "hide"}>
            <BsCircleFill style={{ fontSize: "3px" }}></BsCircleFill>
          </p>
          <div
            className={isOpen ? "hd-menu-con" : "hide"}
            onClick={() => routeTo("#onboarding")}
          >
            <p>How it works</p>
            <div className="hd-menu-line"></div>
          </div>
          <br></br>
          <div
            className={isOpen ? "header-button header-button-mobile" : "hide"}
          >
            <a href="#proposal">Make Enquiry</a>
            <FaQuestionCircle />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

export const LogoFunction = () => {
  return (
    <div className="disablelink">
      <a href="/">
        <div className="header-logo-flex">
          <img
            src={logoWhite}
            alt="MyHospitul Technology Limited"
            className="logo"
          ></img>
          <div>
            <p
              className="mont-sm"
              style={{ fontSize: "22px", fontWeight: "bold", margin: "0px" }}
            >
              <span className="fg-shade-two">My</span>Hospitul
            </p>
            <p
              className="mont-sm"
              style={{
                fontSize: "15px",
                fontWeight: "normal",
                margin: "0px 0px 2px 0px",
              }}
            >
              Technology LLC
            </p>
          </div>
        </div>
      </a>
    </div>
  );
};

export const LogoFunction2 = () => {
  return (
    <div className="disablelink">
      <a href="/">
        <div className="header-logo-flex">
          <img
            src={logoGreen}
            alt="MyHospitul Technology Limited"
            className="logo"
          ></img>
          <div>
            <p
              className="mont-sm"
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                margin: "0px",
                color: "black",
              }}
            >
              <span className="fg-shade-two">My</span>Hospitul
            </p>
            <p
              className="mont-sm"
              style={{
                fontSize: "15px",
                fontWeight: "normal",
                margin: "0px 0px 2px 0px",
                color: "black",
              }}
            >
              Technology LLC
            </p>
          </div>
        </div>
      </a>
    </div>
  );
};
