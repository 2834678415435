import React from "react";
import "../../styles/footer.css";
import { MdCall, MdEmail, MdLocationPin } from "react-icons/md";
import { LogoFunction2 } from "./header";
import { AiFillProduct } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="footer-bg">
        <div className="footer-col">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <LogoFunction2></LogoFunction2>
          </div>
          <br></br>
          <div
            className="flex-left ft-contact"
            onClick={() => navigate("/demo")}
          >
            <AiFillProduct /> &nbsp;&nbsp;
            <p className="footer-sub">MyHospitul Enterprise Solution</p>
          </div>
          <div className="flex-left ft-contact">
            <AiFillProduct /> &nbsp;&nbsp;
            <p className="footer-sub">MyHospitul LabSync</p>
          </div>
          <div className="flex-left ft-contact">
            <AiFillProduct /> &nbsp;&nbsp;
            <p className="footer-sub">MyHospitul CareBridge</p>
          </div>
          <div className="flex-left ft-contact">
            <AiFillProduct /> &nbsp;&nbsp;
            <p className="footer-sub">MyHospitul Reports & Analytics</p>
          </div>
          <div className="flex-left ft-contact">
            <AiFillProduct /> &nbsp;&nbsp;
            <p className="footer-sub">MyHospitul Shop</p>
          </div>
          <div className="flex-left ft-contact">
            <AiFillProduct /> &nbsp;&nbsp;
            <p className="footer-sub">MyHospitul Health Trackers</p>
          </div>
        </div>
        <div className="footer-col">
          <p className="footer-title">Contact Us</p>
          <div className="footer-border"></div>
          <p className="footer-sub">
            <MdLocationPin></MdLocationPin>&nbsp;&nbsp;No 8 Adele Close,
            Rumuodara, Portharcourt, Rivers State, Nigeria
          </p>
          <p className="footer-sub">
            <MdCall></MdCall>&nbsp;&nbsp;
            <a className="ft-contact" href="tel:+2347015790515">
              +234 701 579 0515
            </a>
            ,&nbsp;
            <a className="ft-contact" href="tel:+2347037520334">
              +234 703 752 0334
            </a>
          </p>
          <p className="footer-sub">
            <MdEmail></MdEmail>
            <a className="ft-contact" href="mailto:info@myhospitul.com">
              &nbsp;&nbsp; info@myhospitul.com
            </a>
          </p>
          <br></br>
          <p className="footer-sub footer-social">
            <a
              className="ft-contact"
              href="https://www.facebook.com/MyHospitul/"
            >
              <img
                alt=""
                className="ft-socials"
                src={require("../../assets/images/facebook.png")}
              />
            </a>

            <a
              className="ft-contact"
              href="https://www.instagram.com/myhospitul/"
            >
              <img
                alt=""
                className="ft-socials"
                src={require("../../assets/images/instagram.png")}
              />
            </a>

            <a className="ft-contact" href="https://twitter.com/myhospitul">
              <img
                alt=""
                className="ft-socials"
                src={require("../../assets/images/twitter.png")}
              />
            </a>

            <a
              className="ft-contact"
              href="https://ng.linkedin.com/company/myhospitul"
            >
              <img
                alt=""
                className="ft-socials"
                src={require("../../assets/images/linkedin.jpg")}
              />
            </a>

            <a className="ft-contact" href="https://www.tiktok.com/@myhospitul">
              <img
                alt=""
                className="ft-socials"
                src={require("../../assets/images/tiktok.png")}
              />
            </a>
          </p>
        </div>
        <div className="footer-col">
          <p className="footer-title">Resources</p>
          <div className="footer-border"></div>
          <dl>
            <li className="ft-contact">News & Updates</li>
            <br></br>
            <li className="ft-contact">Documentations</li>
            <br></br>
            <li className="ft-contact">User Guide</li>
            <br></br>
            <li className="ft-contact">AATI Approach</li>
            <br></br>
            <li className="ft-contact">Quick-use Demos</li>
          </dl>
        </div>
      </div>
      <div className="footer-copy">
        <p>Copyright &copy; 2024 MyHospitul Technology LLC</p>
      </div>
    </div>
  );
};

export default Footer;
