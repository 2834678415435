import React, { Component } from 'react';

class Messenger extends Component {
    render() {
        return (
            <div className='messenger'>
                <span style={{fontSize:'13px',backgroundColor:'#fff',padding:'5px 10px', borderRadius:'3px', border:'none'}}>Chat with our <b>Customer Help Desk</b></span>
                <a target='_blank' rel='noreferrer' href='https://wa.me/+2347037520334?text=Hello,I will like to inquire about your healthcare technology'>
                <img style={{width: '50px'}} src={require("../../assets/images/general/whatsapp.png")} alt="whatsapp"/>
                </a>
            </div>
        );
    }
}

export default Messenger;