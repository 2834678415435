import React from "react";
import "../../styles/home/onboarding.css";
import { MdCall } from "react-icons/md";

const Onboarding = () => {
  return (
    <div>
      <div className="onboarding_container">
        <div className="onboarding_subject_holder">
          <div className="onboarding_subject">
            <h3>Go Completely paperless in your medical center in 15 weeks</h3>
            <p>
              MyHospitul enterprise solution helps hospitals switch from paper
              to electronic records, making it easy to track patient records,
              lab results, drug history, and bill payments.
            </p>
            <div className="button-primary" onClick={()=>window.open("https://wa.me/+2347037520334?text=Hello,I want to purchase MyHospitul License for my medical center")}>
              Contact Sales <MdCall></MdCall>
            </div>
          </div>
          <div class="onboarding_image_container">
            <img
              className="onboarding_image"
              src={require("../../assets/images/general/emr.jpg")}
              alt="MyHospitul EMR"
            />
          </div>
        </div>
        <div className="onbording_process">
          <div className="onbording_process_item">
            <p className="onbording_process_item_icon">1</p>
            <div>
              <h4>Contact Sales</h4>
              <p>Book a demo</p>
            </div>
          </div>
          <div className="onbording_process_item">
            <p className="onbording_process_item_icon">2</p>
            <div>
              <h4>Facility QA/QC</h4>
              <p>Ensuring computers meet quality and security standards.</p>
            </div>
          </div>
          <div className="onbording_process_item">
            <p className="onbording_process_item_icon">3</p>
            <div>
              <h4>Installation/Licence Setup</h4>
              <p>First look installation on all computers</p>
            </div>
          </div>
          <div className="onbording_process_item">
            <p className="onbording_process_item_icon">4</p>
            <div>
              <h4>Training</h4>
              <p>Medical & Non-Medical Staff Training</p>
            </div>
          </div>
          <div className="onbording_process_item">
            <p className="onbording_process_item_icon">5</p>
            <div>
              <h4>15 Weeks AATI</h4>
              <p>MyHospitul Digital Transformation strategy</p>
            </div>
          </div>
        </div>
      </div>
      <div className="aati_container_holder">
        <div className="aati_container">
          <div className="aati_container_content">
            <img
              src={require("../../assets/images/general/myhospitul aati.png")}
              alt="MyHospitul AATI"
            />
            <h3 style={{color:"#000",margin:'10px 0px'}}>MyHospitul Digital Strategy</h3>
            <p style={{color:"#000",fontSize:'12px',lineHeight:'1.5',margin:'0px'}}>
              AATI - MyHospitul 15 WEEKS Strategic Digital Transformation
              Approach
            </p>
          </div>
          <img
            className="aati_container_content_img"
            src={require("../../assets/images/general/myhospitul aati2.png")}
            alt="MyHospitul AATI"
          />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
